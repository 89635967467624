<template>
  <div class="main-dashboard">
    <div class="data-carousel-container" v-if="!isBranded">
      <carousel :carouselDataLoaded="carouselDataLoaded"></carousel>
    </div>
    <div class="dashboard-main-graphs" v-if="!firstLoading">
      <div class="data-resume-container">
        <StatusCards
          :dataLoadsStatus="dataLoadsByStatus"
          @formatMyLoadsFilterObject="formatMyLoadsFilterObject"
        />
      </div>
      <div class="graphs-info-container">
        <CardDashboard
          class="graphs-info-container__item graphs-info-container__item--first"
          :class="isBranded ? 'long-graph' : ''"
          :title="'Volume of loads per month'"
          :type="'graphCard'"
        >
          <v-chart
            @click="selectedGraphItemOption($event, 'multi-bar')"
            class="chart"
            :option="optionLoadsPerMonthGraph"
            autoresize
          />
        </CardDashboard>
        <CardDashboard
          class="graphs-info-container__item graphs-info-container__item--second"
          :title="'Invoices payment'"
          :type="'graphCard'"
          v-if="!isBranded"
        >
          <v-chart v-if="hasPayableData" class="chart" :option="optionPayableGraph" autoresize />
          <NotFoundData v-else />
        </CardDashboard>
      </div>
    </div>
    <div v-else>
      <div class="d-flex flex-row field justify-content-between">
        <Skeleton class="skeleton mb-2" width="24%" height="87px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="24%" height="87px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="24%" height="87px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="24%" height="87px"></Skeleton>
      </div>
      <div class="d-flex flex-row field justify-content-between">
        <Skeleton
          class="skeleton mb-2"
          :width="isBranded ? '70%' : '100%'"
          height="400px"
        ></Skeleton>
        <Skeleton class="skeleton mb-2" width="29%" height="400px"></Skeleton>
      </div>
    </div>
    <div class="filters-container">
      <RCSelect
        class="filters-container__select"
        :label="'Select a months filter'"
        :floatLabel="false"
        type="text"
        :requiredField="true"
        :suggestions="optionsFilters"
        v-model="dateTimeFilter"
      >
      </RCSelect>
    </div>
    <Dashboard
      v-if="!loading"
      :loading="loading"
      :renderKey="renderKey"
      :optionModesGraph="optionModesGraph"
      :optionMostUsedAccessorialsGraph="optionMostUsedAccessorialsGraph"
      :optionTopCarriersGraph="optionTopCarriersGraph"
      :optionLoadsPerMonthGraph="optionLoadsPerMonthGraph"
      :optionLoadStatusGraph="optionLoadStatusGraph"
      :optionPayableGraph="optionPayableGraph"
      :optionMapGraph="optionMapGraph"
      :optionMapCandaGraph="optionMapCandaGraph"
      :optionMapMexico="optionMapMexico"
      :hasModesLoadData="hasModesLoadData"
      :hasTopCarriersData="hasTopCarriersData"
      :hasPayableData="hasPayableData"
      :hasMostUsedAccessorialsData="hasMostUsedAccessorialsData"
      :hasLoadsInCanada="hasLoadsInCanada"
      :hasLoadsInMexico:="hasLoadsInMexico"
      :dataLoadsByStatus="dataLoadsByStatus"
      :isFilterApplied="isFilterApplied"
      @getAllDashboardInformation="getAllDashboardInformation"
      @formatMyLoadsFilterObject="formatMyLoadsFilterObject"
      class="dashboard-secondary-graphs"
    />
    <SkeletonDashboard
      :hasLoadsInCanada="hasLoadsInCanada"
      :hasLoadsInMexico="hasLoadsInMexico"
      v-else
    />
  </div>
</template>

<script>
/* eslint-disable no-case-declarations, camelcase */
import dayjs from "dayjs";
import myLoadsFilterSchema from "@/utils/MyLoadsFiltersSchema";
// import { mapGetters } from "vuex";
import Skeleton from "primevue/skeleton";
import RCSelect from "@/components/RCComponents/CustomRCSelect.vue";
import DashboardGraphs from "@/Extend/DashboardGraphs";
import Dashboard from "./Dashboard.vue";
import SkeletonDashboard from "./SkeletonDashboard.vue";
import Carousel from "./components/Carousel.vue";
import StatusCards from "./components/StatusCards.vue";
import CardDashboard from "./components/CardDashboard.vue";
import NotFoundData from "./components/NotFoundData.vue";

export default {
  name: "DashboardContainer",
  mixins: [DashboardGraphs],
  components: {
    Dashboard,
    SkeletonDashboard,
    Carousel,
    RCSelect,
    StatusCards,
    CardDashboard,
    NotFoundData,
    Skeleton,
  },
  data() {
    return {
      dateTimeFilter: 6,
      optionsFilters: [
        { label: "Last 3 Months", value: 3 },
        { label: "Last 6 Months", value: 6 },
        { label: "Last 9 Months", value: 9 },
        { label: "Last 12 Months", value: 12 },
      ],
    };
  },
  computed: {
    // ...mapGetters({ isExecutedTour: "statistics/getUserExecuteDashboardTour" }),
  },
  watch: {
    async dateTimeFilter(newValue) {
      if (newValue) {
        this.$store.commit("statistics/setFiltersMonth", newValue);
        await this.getAllDashboardInformation();
      } else {
        this.$store.commit("statistics/resetFiltersMonth");
      }
    },
    // isExecutedTour: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     if (this.isExecutedTour && !this.firstLoading) {
    //       this.tourDashboard();
    //     }
    //     this.$store.commit("statistics/setUserExecuteDashboardTour", false);
    //   },
    // },
  },
  methods: {
    async getAllDashboardInformation() {
      this.isFilterApplied = true;
      await this.getAllDashboardInfo();
    },
    // tourDashboard() {
    //   const { driver } = window.driver.js;
    //   const dashboardTourSchema = driver({
    //     showProgress: true,
    //     showButtons: ["next", "previous"],
    //     steps: [
    //       {
    //         element: ".dashboard-main-graphs",
    //         popover: {
    //           title: "View Load Status and Volume",
    //           /* eslint-disable */
    //           description:
    // eslint-disable-next-line max-len
    //             "You can now see the status of your loads and the cargo volume you've moved. This feature allows you to stay updated and manage your shipments efficiently.",
    //           side: "left",
    //           align: "start",
    //         },
    //       },
    //       {
    //         element: ".filters-container",
    //         popover: {
    //           title: "Time Period Filters",
    //           description:
    //             /* eslint-disable */
    //             "Our new feature allows you to filter the historical data of your loads by specific time periods.",
    //           side: "left",
    //           align: "start",
    //         },
    //       },
    //       {
    //         element: ".dashboard-secondary-graphs",
    //         popover: {
    //           title: "Geographical Insights",
    //           description:
    //             /* eslint-disable */
    // eslint-disable-next-line max-len
    //             "Learn more about the locations where you have moved the most loads. The maps highlight states with the number of your loads. The darker areas indicate higher activity, while lighter areas show less activity.",
    //           side: "top",
    //           align: "center",
    //         },
    //       },
    //     ],
    //   });
    //   dashboardTourSchema.drive();
    // },
    formatMyLoadsFilterObject(data) {
      const { filterType } = data;
      const objectFilters = { ...myLoadsFilterSchema };
      switch (filterType) {
        case "status":
          objectFilters.statuses = this.myLoadsStatusFilters(data);
          break;
        case "carrier":
          objectFilters.carrier = data.carrier;
          break;
        case "accessorials":
          objectFilters.accessorial_id = data.accessorial;
          break;
        case "dates":
          const { actual_pickup_date_gt, actual_pickup_date_lt, created_date_gt } = data.dates;
          objectFilters.actual_pickup_date_gt = actual_pickup_date_gt;
          objectFilters.actual_pickup_date_lt = actual_pickup_date_lt;
          objectFilters.created_date_gt = created_date_gt;
          objectFilters.statuses = this.myLoadsStatusFilters("dates");
          break;
        case "map":
          const today = dayjs().format("YYYY-MM-DD");
          const pastDate = dayjs().subtract(this.dateTimeFilter, "month").format("YYYY-MM-DD");
          const eighteenWeeksBefore = dayjs(pastDate).subtract(18, "week").format("YYYY-MM-DD");
          objectFilters.actual_pickup_date_gt = pastDate;
          objectFilters.actual_pickup_date_lt = today;
          objectFilters.created_date_gt = eighteenWeeksBefore;
          objectFilters.state_search = data.state;
          break;
        case "modes":
          objectFilters.modes = data.modes;
          break;
        default:
          break;
      }
      this.$router.push({
        name: "MyLoads",
        params: { filters: objectFilters },
      });
    },
    myLoadsStatusFilters(data) {
      const statusMapping = {
        quoted: ["unassigned", "quotes requested", "quotes received", "quoted"],
        assigned: ["tendered", "dispatched", "assigned"],
        "in transit": ["in transit"],
        delivered: ["delivered", "completed"],
      };
      if (data === "dates") {
        return [...statusMapping.delivered, "in transit"];
      }
      const statusesSelected = statusMapping[data.status.name.toLowerCase()] || [];
      return statusesSelected;
    },
    selectedGraphItemOption(params, graphName) {
      switch (graphName) {
        case "multi-bar":
          const { year, month } = params.data;
          const { actual_pickup_date_gt, actual_pickup_date_lt, created_date_gt } =
            this.formatDatesToFilter({
              year,
              month,
            });
          this.formatMyLoadsFilterObject({
            filterType: "dates",
            dates: { actual_pickup_date_gt, actual_pickup_date_lt, created_date_gt },
          });
          break;
        default:
          break;
      }
    },
    formatDatesToFilter({ year, month }) {
      const monthIndex = new Date(`${month} 1, ${year}`).getMonth() + 1;
      const firstDayOfMonth = dayjs(`${year}-${monthIndex}-01`)
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastDayOfMonth = dayjs(`${year}-${monthIndex}-01`).endOf("month").format("YYYY-MM-DD");
      const eighteenWeeksBefore = dayjs(firstDayOfMonth).subtract(18, "week").format("YYYY-MM-DD");
      return {
        actual_pickup_date_gt: firstDayOfMonth,
        actual_pickup_date_lt: lastDayOfMonth,
        created_date_gt: eighteenWeeksBefore,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.main-dashboard {
  padding-top: 1.5rem;
  width: 97%;
  height: 100%;
  margin: 0 auto;
}

.data-carousel-container {
  width: 100%;
  height: 100%;
  @media (min-width: 1800px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.filters-container {
  display: flex;
  gap: 20px;
  margin: 20px 0px 20px 10px;
  &__select {
    width: 250px;
  }
}

.filter-button-container {
  background: none;
  padding: 8px;
  border-radius: 13px;
}

.graphs-info-container {
  display: grid;
  grid-template-rows: 400px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  &__item {
    height: 400px;
    &--first {
      grid-column: span 4;
    }
    &--second {
      grid-column: span 1;
    }
    &.long-graph {
      grid-column: span 5;
    }
    @media (max-width: 1235px) {
      &--first {
        grid-column: span 5;
      }
      &--second {
        grid-column: span 5;
      }
    }
  }
}

::v-deep .rc-suggestions-list__item {
  color: $color-primary-company;
}

::v-deep .el-input__prefix {
  color: $color-primary-company;
}

::v-deep .el-input--prefix .el-input__inner {
  border: 1px solid $color-primary-company;
}
</style>
