<template>
  <div class="carousel-container">
    <CarouselScheme
      :carouselDataLoaded="carouselDataLoaded"
      :intervalValue="intervalValue"
      :typeCarousel="typeCarousel"
    ></CarouselScheme>
  </div>
</template>

<script>
import onResize from "@/Extend/onResize";
import CarouselScheme from "./CarouselScheme.vue";

/* eslint-disable */
export default {
  name: "Carousel",
  components: {
    CarouselScheme,
  },
  mixins: [onResize],
  props: {
    carouselDataLoaded: Boolean,
  },
  watch: {
    screenWidth(value) {
      if (value > 1800) {
        this.typeCarousel = "card";
        return;
      }
      this.typeCarousel = "";
    },
  },
  data() {
    return {
      intervalValue: 3000,
      typeCarousel: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.carousel-container {
  @media (min-width: 1800px) {
    width: 1600px;
    position: relative;
  }
  /* El siguiente codigo es temporal, para deshabilitar el carousel para pantallas tan pequeñas, mientras luego se busca una nueva organizacion de las dimensiones */
  @media (max-width: 820px) {
    .carousel-container {
      display: none;
    }
  }
}
</style>
