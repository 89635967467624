/* eslint-disable max-len */
import onReSize from "@/Extend/onResize";
import * as echarts from "echarts/core";
import { MapChart } from "echarts/charts";
import { ToolboxComponent, VisualMapComponent } from "echarts/components";
import { mapGetters } from "vuex";
import usaMap from "../utils/usa.json";
import canadaMap from "../utils/canada.json";
import mexicoMap from "../utils/mexico.json";

export default {
  name: "Dashboard",
  mixins: [onReSize],
  data() {
    return {
      optionModesGraph: {},
      optionTopCarriersGraph: {},
      optionLoadsPerMonthGraph: {},
      optionMostUsedAccessorialsGraph: {},
      optionLoadStatusGraph: {},
      optionPayableGraph: {},
      optionMapGraph: {},
      optionMapCandaGraph: {},
      optionMapMexico: {},
      renderKey: 0,
      loading: false,
      firstLoading: true,
      isFilterApplied: false,
      isBranded: null,
      firstLoadedDashboardData: true,
      carouselDataLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      dataModesGraph: "statistics/getDataLoadsByMode",
      dataTopCarriers: "statistics/getDataTopCarriers",
      dataMostUsedAccessorials: "statistics/getDataMostUsedAccessorials",
      dataPayableByStatus: "statistics/getDataPaybleByStatus",
      dataLoadsByStatus: "statistics/getDataLoadsByStatus",
      dataVolumeLoadsMonthly: "statistics/getDataVolumeLoadsMonthly",
      dataMap: "statistics/getDataMap",
    }),
    hasMostUsedAccessorialsData() {
      return this.dataMostUsedAccessorials && this.dataMostUsedAccessorials.length > 0;
    },
    hasModesLoadData() {
      return this.dataModesGraph && this.dataModesGraph.length > 0;
    },
    hasTopCarriersData() {
      return this.dataTopCarriers && this.dataTopCarriers.length > 0;
    },
    hasPayableData() {
      return this.dataPayableByStatus && this.dataPayableByStatus.length > 0;
    },
    hasLoadsInMexico() {
      const { maxValue } = this.getMaxAndMinValueToMaps(
        this.formatResponseData(this.dataMap, "map", "MX")
      );
      if (maxValue >= 1) {
        return true;
      }
      return false;
    },
    hasLoadsInCanada() {
      const { maxValue } = this.getMaxAndMinValueToMaps(
        this.formatResponseData(this.dataMap, "map", "CA")
      );
      if (maxValue >= 1) {
        return true;
      }
      return false;
    },
  },
  watch: {
    screenWidth() {
      this.renderKey += 1;
    },
  },
  async created() {
    this.isBranded = this.$store.getters["login/getTokenInfo"].is_branded;
    await Promise.all([
      this.getDataLoadByStatus(),
      this.getDataPayableByStatus(),
      this.getDataVolumeLoadsMonthly(),
    ]);
    await this.getAllDashboardInfo();
    this.carouselDataLoaded = true;
  },
  methods: {
    async getCarouselInfo() {
      await this.$store.dispatch("carousel/getAllCarouselInfo");
    },
    async getAllDashboardInfo() {
      try {
        this.loading = true;
        await Promise.all([
          this.getCarouselInfo(),
          this.getDataLoadsByMode(),
          this.getDataTopCarriers(),
          this.getDataMostUsedAccessorials(),
          this.getDataOfMap(),
        ]);
        this.firstLoading = false;
        // if (this.firstLoadedDashboardData) {
        //   setTimeout(() => {
        //     this.firstLoadedDashboardData = false;
        //     this.$store.commit("statistics/setLoadedDashboardInformation", true);
        //   }, 0);
        // }
        this.renderKey += 1;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    createModesGraph() {
      const customColors = [
        "#043663",
        "#0077B6",
        "#0096C7",
        "#023E8A",
        "#15665E",
        "#695CBF",
        "#6CB3AC",
        "#2100FF",
        "#3A0CA3",
        "#48CAE4",
        "#49327E",
        "#A8E7A8",
        "#E85D04",
        "#F22A1D",
        "#F6AE2D",
        "#FD9653",
        "#FFD60A",
        "#C07C78",
        "#ED776F",
      ];
      this.optionModesGraph = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "0%",
          left: "center",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            center: ["50%", "38%"],
            data: this.formatResponseData(this.dataModesGraph, "pie"),
            color: customColors,
          },
        ],
      };
    },
    createTopCarriersGraph() {
      this.optionTopCarriersGraph = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "1%",
          right: "4%",
          top: "5%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: {
          type: "log",
        },
        yAxis: {
          type: "category",
          data: this.dataTopCarriers.length > 0 ? this.formatResponseData(this.dataTopCarriers, "bar")[0].reverse() : null,
        },
        series: [
          {
            type: "bar",
            barWidth: "30%",
            data: this.dataTopCarriers.length > 0 ? this.formatResponseData(this.dataTopCarriers, "bar")[1].reverse() : null,
          },
        ],
      };
    },
    createVolumeLoadsPerMonthGraph() {
      const customColors = ["#03045E", "#0077B6", "#E85D04", "#F6AE2D", "#046BF1", "#3A0CA3"];
      let months = [];
      if (this.dataVolumeLoadsMonthly && this.dataVolumeLoadsMonthly.length > 0) {
        months = this.dataVolumeLoadsMonthly.map((item) => item.keys.month).reverse();
      }
      this.optionLoadsPerMonthGraph = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: 100,
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          data: months,
        },
        yAxis: {
          type: "value",
          inverse: false,
          axisLabel: {
            formatter(value) {
              return `{${value}| }\n{value|${value}}`;
            },
            margin: 20,
            rich: {
              value: {
                lineHeight: 30,
                align: "center",
              },
              Sunny: {
                height: 40,
                align: "center",
                backgroundColor: {},
              },
              Cloudy: {
                height: 40,
                align: "center",
                backgroundColor: {},
              },
              Showers: {
                height: 40,
                align: "center",
                backgroundColor: {},
              },
            },
          },
        },
        series: [
          {
            name: "Loads",
            type: "bar",
            data: this.formatResponseData(this.dataVolumeLoadsMonthly, "multi-bar"),
            itemStyle: { color: customColors[0] },
          },
        ],
        color: customColors,
      };
    },
    createMostUsedAccessorialGraph() {
      this.optionMostUsedAccessorialsGraph = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "1%",
          right: "4%",
          top: "5%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: {
          type: "log",
        },
        yAxis: {
          type: "category",
          data: this.dataMostUsedAccessorials.length > 0 ? this.formatResponseData(this.dataMostUsedAccessorials, "bar")[0].reverse() : null,
        },
        series: [
          {
            type: "bar",
            barWidth: "30%",
            data: this.dataMostUsedAccessorials.length > 0 ? this.formatResponseData(this.dataMostUsedAccessorials, "bar")[1].reverse() : null,
          },
        ],
      };
    },
    createLoadByStatusGraph() {
      this.optionLoadStatusGraph = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "0%",
          left: "center",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            center: ["50%", "38%"],
            data: [
              { value: 40, name: "Quoted", itemStyle: { color: "#3a3a3a" } },
              { value: 100, name: "Assigned", itemStyle: { color: "#00ccff" } },
              { value: 150, name: "In Transit", itemStyle: { color: "#ff6600" } },
              { value: 200, name: "Delivered", itemStyle: { color: "#336600" } },
              { value: 250, name: "Cancelled", itemStyle: { color: "#FF0A0A" } },
            ],
          },
        ],
      };
    },
    createInvoicesPaymentGraph() {
      const customColors = ["#03045E", "#695CBF"];
      this.optionPayableGraph = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            const { value } = params;
            const formattedValue = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(value);
            return `${formattedValue}`;
          },
        },
        legend: {
          bottom: "15%",
          left: "center",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            center: ["50%", "38%"],
            data: this.formatResponseData(this.dataPayableByStatus, "pie"),
          },
        ],
        color: customColors,
      };
    },
    createMapGraph() {
      echarts.use([MapChart, ToolboxComponent, VisualMapComponent]);
      const { maxValue, minValue } = this.getMaxAndMinValueToMaps(
        this.formatResponseData(this.dataMap, "map", "US")
      );
      this.optionMapGraph = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            if (params.data) {
              return `<strong style="text-align: start;">${params.data.name}</strong><br/>Pickup Quantity: ${params.data.pickup_quantity}<br/>Delivery Quantity: ${params.data.delivery_quantity}`;
            }
            return "";
          },
        },
        visualMap: {
          left: "right",
          min: minValue,
          max: maxValue,
          inRange: {
            color: ["#e0f3f8", "#abd9e9", "#74add1", "#4575b4", "#03045E"],
          },
          text: ["High", "Low"],
          calculable: true,
        },
        toolbox: {
          show: false,
          left: "left",
          top: "top",
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        series: [
          {
            zoom: 1.2,
            type: "map",
            roam: false,
            map: "USA",
            data: this.formatResponseData(this.dataMap, "map", "US"),
          },
        ],
      };
      echarts.registerMap("USA", usaMap, this.optionMapGraph);
    },
    createCanadaMap() {
      echarts.use([MapChart, ToolboxComponent, VisualMapComponent]);
      const { maxValue, minValue } = this.getMaxAndMinValueToMaps(
        this.formatResponseData(this.dataMap, "map", "CA")
      );
      this.optionMapCandaGraph = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            if (params.data) {
              return `<strong style="text-align: start;">${params.data.name}</strong><br/>Pickup Quantity: ${params.data.pickup_quantity}<br/>Delivery Quantity: ${params.data.delivery_quantity}`;
            }
            return "";
          },
        },
        visualMap: {
          left: "right",
          min: minValue,
          max: maxValue,
          inRange: {
            color: ["#e0f3f8", "#abd9e9", "#74add1", "#4575b4", "#03045E"],
          },
          text: ["High", "Low"],
          calculable: true,
        },
        toolbox: {
          show: false,
          left: "left",
          top: "top",
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        series: [
          {
            zoom: 1.3,
            type: "map",
            roam: false,
            map: "CANADA",
            data: this.formatResponseData(this.dataMap, "map", "CA"),
          },
        ],
      };
      echarts.registerMap("CANADA", canadaMap, this.optionMapCandaGraph);
    },
    createMexicoMap() {
      echarts.use([MapChart, ToolboxComponent, VisualMapComponent]);
      const { maxValue, minValue } = this.getMaxAndMinValueToMaps(
        this.formatResponseData(this.dataMap, "map", "MX")
      );
      this.optionMapMexico = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            if (params.data) {
              return `<strong style="text-align: start;">${params.data.name}</strong><br/>Pickup Quantity: ${params.data.pickup_quantity}<br/>Delivery Quantity: ${params.data.delivery_quantity}`;
            }
            return "";
          },
        },
        visualMap: {
          left: "right",
          bottom: "0%",
          min: minValue,
          max: maxValue,
          inRange: {
            color: ["#e0f3f8", "#abd9e9", "#74add1", "#4575b4", "#03045E"],
          },
          text: ["High", "Low"],
          calculable: true,
        },
        toolbox: {
          show: false,
          left: "left",
          top: "top",
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        series: [
          {
            type: "map",
            roam: false,
            map: "MEXICO",
            data: this.formatResponseData(this.dataMap, "map", "MX"),
          },
        ],
      };
      echarts.registerMap("MEXICO", mexicoMap, this.optionMapMexico);
    },
    formatResponseData(data, mode, country) {
      if (!data || data.length <= 0) {
        return [];
      }
      let response = null;
      switch (mode) {
        // Torta
        case "pie":
          response = this.formatGraphPieData(data);
          break;
        // Barras
        case "bar":
          response = this.formatGraphBarData(data);
          break;
        case "multi-bar":
          // Multi barras
          response = this.formatGraphMultiBar(data);
          break;
        case "map":
          response = this.buildCountryData(data, country);
          break;
        default:
          break;
      }
      return response;
    },
    formatGraphPieData(data) {
      if (data) {
        const payload = [];
        data.forEach((element) => {
          const name = element.keys.mode_name || element.keys.status;
          const value = element.quantity;
          payload.push({ name, value });
        });
        return payload;
      }
      return [];
    },
    formatGraphBarData(data) {
      if (data) {
        const yAxisData = data.map((item) => item.keys.carrier_name || item.keys.accessorial_name);
        const seriesData = data.map((item) => ({
          value: item.quantity,
          id: item.keys.accessorial_id,
        }));
        const customColors = [
          "#03045E",
          "#023E8A",
          "#0077B6",
          "#0096C7",
          "#00B4D8",
          "#48CAE4",
          "#90E0EF",
        ];
        const filteredData = seriesData
          .map((item, index) => ({ quantity: item.value, index, ...item }))
          .filter((item) => item.quantity > 1);
        const dataFormatted = filteredData.map((item) => ({
          value: item.quantity,
          id: item.id,
          itemStyle: { borderRadius: [0, 15, 15, 0], color: customColors[item.index] },
        }));
        const filteredYAxisData = filteredData.map((item) => yAxisData[item.index]);
        return [filteredYAxisData, dataFormatted];
      }
      return [];
    },
    formatGraphMultiBar(data) {
      if (data) {
        const formattedData = data.reverse().map((item) => ({
          value: item.quantity,
          year: item.keys.year,
          month: item.keys.month,
        }));
        return formattedData;
      }
      return [];
    },
    getMaxAndMinValueToMaps(data) {
      let maxValue = 0;
      let minValue = 0;
      data.forEach((item) => {
        if (item.value > maxValue) {
          maxValue = item.value;
        }
        if (item.value < minValue) {
          minValue = item.value;
        }
      });
      return { maxValue, minValue };
    },
    buildCountryData(data, country) {
      let stateNames = [];
      if (data) {
        if (country === "US") {
          stateNames = [
            "Alabama",
            "Alaska",
            "Arizona",
            "Arkansas",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "Florida",
            "Georgia",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Pennsylvania",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "Utah",
            "Vermont",
            "Virginia",
            "Washington",
            "West Virginia",
            "Wisconsin",
            "Wyoming",
          ];
        } else if (country === "CA") {
          stateNames = [
            "Newfoundland and Labrador",
            "Prince Edward Island",
            "Nova Scotia",
            "New Brunswick",
            "Quebec",
            "Ontario",
            "Manitoba",
            "Saskatchewan",
            "Alberta",
            "British Columbia",
            "Yukon",
            "Northwest Territories",
            "Nunavut",
          ];
        } else if (country === "MX") {
          stateNames = [
            "Aguascalientes",
            "Baja California",
            "Baja California Sur",
            "Campeche",
            "Chiapas",
            "Chihuahua",
            "Coahuila",
            "Colima",
            "Ciudad de México",
            "Durango",
            "Guanajuato",
            "Guerrero",
            "Hidalgo",
            "Jalisco",
            "México",
            "Michoacán",
            "Morelos",
            "Nayarit",
            "Nuevo León",
            "Oaxaca",
            "Puebla",
            "Querétaro",
            "Quintana Roo",
            "San Luis Potosí",
            "Sinaloa",
            "Sonora",
            "Tabasco",
            "Tamaulipas",
            "Tlaxcala",
            "Veracruz",
            "Yucatán",
            "Zacatecas",
          ];
        }
        const stateQuantities = stateNames.map((state) => ({
          name: state,
          value: 0,
          pickup_quantity: 0,
          delivery_quantity: 0,
        }));
        data.forEach((item) => {
          if (item.keys.country === country) {
            const existingState = stateQuantities.find((state) => state.name === item.keys.state);
            if (existingState) {
              existingState.value += item.quantity;
              existingState.pickup_quantity += item.keys.is_pickup ? item.quantity : 0;
              existingState.delivery_quantity += item.keys.is_pickup ? 0 : item.quantity;
            }
          }
        });
        return stateQuantities;
      }
      return [];
    },
    async getDataLoadByStatus() {
      await this.$store.dispatch("statistics/getCountLoadByStatus");
      this.createLoadByStatusGraph();
    },
    async getDataLoadsByMode() {
      this.$store.commit("statistics/setDataLoadsByMode", null);
      await this.$store.dispatch("statistics/getDataLoadsByMode");
      this.createModesGraph();
    },
    async getDataTopCarriers() {
      this.$store.commit("statistics/setDataTopCarriers", [[], []]);
      await this.$store.dispatch("statistics/getCountByCarriers");
      this.createTopCarriersGraph();
    },
    async getDataMostUsedAccessorials() {
      this.$store.commit("statistics/setDataMostUsedAccessorials", [[], []]);
      await this.$store.dispatch("statistics/getCountAccessorialsByName");
      this.createMostUsedAccessorialGraph();
    },
    async getDataPayableByStatus() {
      await this.$store.dispatch("statistics/getCountPayableByStatus");
      this.createInvoicesPaymentGraph();
    },
    async getDataVolumeLoadsMonthly() {
      await this.$store.dispatch("statistics/getVolumeLoadsMonthly");
      this.createVolumeLoadsPerMonthGraph();
    },
    async getDataOfMap() {
      await this.$store.dispatch("statistics/getDataOfMap");
      this.createMapGraph();
      this.createCanadaMap();
      this.createMexicoMap();
    },
  },
};
