<template>
  <div class="card-dashboard">
    <div class="card-dashboard__graph" v-if="type === 'graphCard'">
      <div class="card-dashboard-title">
        {{ title }}
      </div>
      <slot />
    </div>
    <div class="card-dashboard__card" v-else>
      <div class="card-logo" :style="{ 'background-color': option.backgroundColor }">
        <img :src="option.image" alt="" />
      </div>
      <div class="card-info">
        <span class="card-info__title" :style="{ color: option.color }">{{ option.name }}</span>
        <span class="card-info__value">{{ option.value }} Loads</span>
        <span class="card-info__value">{{ option.percentage }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardDashboard",
  props: {
    title: String,
    type: String,
    option: Object,
  },
};
</script>

<style lang="scss" scoped>
.card-dashboard {
  padding: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  &__graph {
    height: 100%;
    width: 100%;
  }
  &__card {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
.card-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 25px;
  }
}
.card-info {
  display: flex;
  flex-direction: column;
  text-align: start;
  color: #9d9d9d;
  font-family: $font-family-portal;
  &__title {
    font-weight: bold;
  }
}
.card-dashboard-title {
  padding-top: 3px;
  text-align: start;
  font-family: $font-family-portal;
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
