<template>
  <div class="dashboard-container">
    <div class="graphs-info-container" :key="renderKey">
      <CardDashboard
        class="graphs-info-container__item graphs-info-container__item--first"
        :title="'Inland modes share'"
        :type="'graphCard'"
      >
        <v-chart @click="selectedGraphItemOption($event, 'modes')" v-if="hasModesLoadData" class="chart" :option="optionModesGraph" autoresize />
        <NotFoundData v-else />
      </CardDashboard>
      <CardDashboard
        class="graphs-info-container__item graphs-info-container__item--second"
        :title="'Top Carriers'"
        :type="'graphCard'"
      >
        <v-chart
          @click="selectedGraphItemOption($event, 'carrier')"
          v-if="hasTopCarriersData"
          class="chart"
          :option="optionTopCarriersGraph"
          autoresize
        />
        <NotFoundData v-else />
      </CardDashboard>
      <CardDashboard
        class="graphs-info-container__item graphs-info-container__item--third"
        :title="'Most used accessorial'"
        :type="'graphCard'"
      >
        <v-chart
          @click="selectedGraphItemOption($event, 'accessorials')"
          v-if="hasMostUsedAccessorialsData"
          class="chart"
          :option="optionMostUsedAccessorialsGraph"
          autoresize
        />
        <NotFoundData v-else />
      </CardDashboard>
      <CardDashboard
        class="graphs-info-container__item graphs-info-container__item--fourth"
        :title="'USA'"
        :type="'graphCard'"
      >
        <v-chart
          @click="selectedGraphItemOption($event, 'map')"
          class="chart"
          id="map-chart"
          :option="optionMapGraph"
          autoresize
        />
      </CardDashboard>
      <CardDashboard
        class="graphs-info-container__item graphs-info-container__item--fifth"
        :class="!hasLoadsInCanada ? 'long-map' : ''"
        :title="'MEXICO'"
        :type="'graphCard'"
        v-if="hasLoadsInMexico"
      >
        <v-chart
          @click="selectedGraphItemOption($event, 'map')"
          class="chart"
          id="map-chart"
          :option="optionMapMexico"
          autoresize
        />
      </CardDashboard>
      <CardDashboard
        class="graphs-info-container__item graphs-info-container__item--sixth"
        :class="!hasLoadsInMexico ? 'long-map' : ''"
        :title="'CANADA'"
        :type="'graphCard'"
        v-if="hasLoadsInCanada"
      >
        <v-chart
          @click="selectedGraphItemOption($event, 'map')"
          class="chart"
          id="map-chart"
          :option="optionMapCandaGraph"
          autoresize
        />
      </CardDashboard>
    </div>
  </div>
</template>

<script>
import modesPicklist from "@/utils/MyLoadsModesPicklist";
import CardDashboard from "./components/CardDashboard.vue";
import NotFoundData from "./components/NotFoundData.vue";

export default {
  name: "Dashboard",
  components: {
    CardDashboard,
    NotFoundData,
  },
  props: {
    loading: Boolean,
    renderKey: Number,
    optionModesGraph: Object,
    optionTopCarriersGraph: Object,
    optionLoadsPerMonthGraph: Object,
    optionLoadStatusGraph: Object,
    optionMostUsedAccessorialsGraph: Object,
    optionPayableGraph: Object,
    optionMapGraph: Object,
    optionMapCandaGraph: Object,
    optionMapMexico: Object,
    dataLoadsByStatus: Array,
    hasModesLoadData: Boolean,
    hasTopCarriersData: Boolean,
    hasPayableData: Boolean,
    hasMostUsedAccessorialsData: Boolean,
    hasLoadsInMexico: Boolean,
    hasLoadsInCanada: Boolean,
    isFilterApplied: Boolean,
  },
  methods: {
    selectedGraphItemOption(params, graphName) {
      const { name } = params;
      switch (graphName) {
        case "carrier":
          this.$emit("formatMyLoadsFilterObject", { filterType: graphName, carrier: name });
          break;
        case "accessorials":
          // eslint-disable-next-line no-case-declarations
          const { id } = params.data;
          this.$emit("formatMyLoadsFilterObject", { filterType: graphName, accessorial: id });
          break;
        case "map":
          this.$emit("formatMyLoadsFilterObject", { filterType: graphName, state: name });
          break;
        case "modes":
          // eslint-disable-next-line no-case-declarations
          const [completeModeInformation] = modesPicklist.filter((mode) => mode.name === name);
          this.$emit("formatMyLoadsFilterObject", { filterType: graphName, modes: [completeModeInformation.id] });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  height: 93%;
  width: 100%;
}

.graphs-info-container {
  display: grid;
  grid-template-rows: 450px;
  grid-template-columns: 1fr;
  gap: 20px;
  &__item {
    height: 450px;
  }
}

#map-chart {
  height: 93%;
}

::v-deep .vue-echarts-inner {
  height: 100%;
}

@media (min-width: 1235px) {
  .graphs-info-container {
    grid-template-columns: 1fr 1fr;
    &__item {
      &--fifth {
        grid-column: span 2;
      }
      &--sixth {
        grid-column: span 2;
      }
      &--seventh {
        grid-column: span 2;
      }
      &--eighth {
        grid-column: span 2;
      }
    }
  }
}

@media (min-width: 1635px) {
  .graphs-info-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    &__item {
      &--first {
        grid-column: span 1;
      }
      &--second {
        grid-column: span 2;
      }
      &--third {
        grid-column: span 2;
      }
      &--fourth {
        grid-column: span 5;
      }
      &--fifth {
        grid-column: span 2;
      }
      &--sixth {
        grid-column: span 3;
      }
      &.long-map {
        grid-column: span 5;
      }
    }
  }
}
</style>
