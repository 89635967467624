<template>
  <div class="skeleton-dashboard">
    <div v-if="screenWidth >= 1634">
      <div class="d-flex flex-row field justify-content-between mb-3">
        <Skeleton class="skeleton mb-2" width="19%" height="450px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="39%" height="450px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="39%" height="450px"></Skeleton>
      </div>
      <div class="d-flex flex-row field justify-content-between mb-3">
        <Skeleton class="skeleton mb-2" width="100%" height="450px"></Skeleton>
      </div>
      <div class="d-flex flex-row field justify-content-between mb-3">
        <Skeleton class="skeleton mb-2" width="32%" height="450px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="65%" height="450px"></Skeleton>
      </div>
    </div>
    <div v-else-if="screenWidth >= 1234">
      <div class="d-flex flex-row field justify-content-between mb-3">
        <Skeleton class="skeleton mb-2" width="49%" height="450px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="49%" height="450px"></Skeleton>
      </div>
      <div class="d-flex flex-row field justify-content-between mb-3">
        <Skeleton class="skeleton mb-2" width="49%" height="450px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="49%" height="450px"></Skeleton>
      </div>
      <div class="d-flex flex-row field justify-content-between mb-3">
        <Skeleton class="skeleton mb-2" width="100%" height="450px"></Skeleton>
      </div>
      <div class="d-flex flex-row field justify-content-between mb-3">
        <Skeleton class="skeleton mb-2" width="100%" height="450px"></Skeleton>
      </div>
    </div>
    <div v-else>
      <div class="d-flex flex-column field justify-content-between mb-3">
        <Skeleton class="skeleton mb-2" width="100%" height="450px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="100%" height="450px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="100%" height="450px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="100%" height="450px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="100%" height="450px"></Skeleton>
        <Skeleton class="skeleton mb-2" width="100%" height="450px"></Skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
import onReSize from "@/Extend/onResize";

export default {
  name: "SkeletonDashboard",
  mixins: [onReSize],
  props: {
    hasLoadsInMexico: Boolean,
    hasLoadsInCanada: Boolean
  },
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
</style>
