<template>
  <div class="not-found">
    <i class="ion ion-file-tray-outline not-found__icon"></i>
    <p class="not-found__text">No data were found.</p>
  </div>
</template>

<script>
export default {
  name: "NotFoundData",
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  &__icon {
    font-size: 2rem;
  }
  &__text {
    font-size: 1.1rem;
  }
}
</style>
