<template>
  <div class="status-card">
    <ul class="status-card-list">
      <li class="status-card-list__item" v-for="option in options" :key="option.status" @click="selectStatusOption(option)">
        <CardDashboard :type="'statusCard'" :option="option" />
      </li>
    </ul>
  </div>
</template>

<script>
import CardDashboard from "./CardDashboard.vue";

export default {
  name: "StatusCard",
  components: {
    CardDashboard,
  },
  props: {
    dataLoadsStatus: Array,
  },
  data() {
    return {
      options: [
        {
          status: "Quotes Received",
          name: "Quoted",
          value: 0,
          percentage: 25,
          // eslint-disable-next-line global-require
          image: require("@/assets/scss/icons/Quoted.svg"),
          color: "#043663",
          backgroundColor: "#B3C2D0",
        },
        {
          status: "Assigned",
          name: "Assigned",
          value: 0,
          percentage: 25,
          // eslint-disable-next-line global-require
          image: require("@/assets/scss/icons/Assigned.svg"),
          color: "#E85D02",
          backgroundColor: "#ffe0b2",
        },
        {
          status: "In Transit",
          name: "In Transit",
          value: 0,
          percentage: 25,
          // eslint-disable-next-line global-require
          image: require("@/assets/scss/icons/inTransit.svg"),
          color: "#046BF1",
          backgroundColor: "#D3E1F6",
        },
        {
          status: "Delivered",
          name: "Delivered",
          value: 0,
          percentage: 25,
          // eslint-disable-next-line global-require
          image: require("@/assets/scss/icons/Delivered.svg"),
          color: "#3A0CA3",
          backgroundColor: "#D3CBE6",
        },
      ],
    };
  },
  watch: {
    dataLoadsStatus: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          value.forEach((dataItem) => {
            // Valores de Quoted = Pending + Unassigned + Quotes Received
            if (
              dataItem.keys.load_status.toLowerCase() === "pending" ||
              dataItem.keys.load_status.toLowerCase() === "unassigned" ||
              dataItem.keys.load_status.toLowerCase() === "quotes received"
            ) {
              const quotedOption = this.options.find(
                (option) => option.status === "Quotes Received"
              );
              if (quotedOption) {
                quotedOption.value += dataItem.quantity;
              }
            }

            // Valores de Delivered = Delivered + Completed
            if (
              dataItem.keys.load_status.toLowerCase() === "completed" ||
              dataItem.keys.load_status.toLowerCase() === "delivered"
            ) {
              const deliveredOption = this.options.find((option) => option.status === "Delivered");
              if (deliveredOption) {
                deliveredOption.value += dataItem.quantity;
              }
            }

            // Asignar valor a assigned
            if (dataItem.keys.load_status.toLowerCase() === "assigned") {
              const assignedOption = this.options.find((option) => option.status === "Assigned");
              if (assignedOption) {
                assignedOption.value += dataItem.quantity;
              }
            }
            if (dataItem.keys.load_status.toLowerCase() === "in transit") {
              const assignedOption = this.options.find((option) => option.status === "In Transit");
              if (assignedOption) {
                assignedOption.value += dataItem.quantity;
              }
            }
          });
          this.calculatePercentage();
        }
      },
    },
  },
  methods: {
    calculatePercentage() {
      const totalValue = this.options.reduce((total, option) => total + option.value, 0);
      let remainingPercentage = 100;
      this.options.forEach((option) => {
        if (totalValue > 0) {
          const percentage = Math.round((option.value / totalValue) * 100);
          option.percentage = percentage;
          remainingPercentage -= percentage;
        } else {
          option.percentage = 0;
        }
      });
      if (remainingPercentage > 0) {
        this.options[this.options.length - 1].percentage += remainingPercentage;
      }
    },
    selectStatusOption(status) {
      this.$emit("formatMyLoadsFilterObject", { filterType: "status", status });
    }
  },
};
</script>

<style lang="scss" scoped>
.status-card-list {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 15px;
  list-style: none;
  &__item {
    width: 25%;
    min-width: 150px;
    cursor: pointer;
    @media (max-width: 600px) {
      width: 48%;
    }
    @media (max-width: 381px) {
      width: 99%;
    }
  }
  @media (max-width: 999px) {
    flex-wrap: wrap;
  }
}
</style>
