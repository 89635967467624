<template>
  <div class="carousel-container">
    <el-carousel
      trigger="click"
      :interval="intervalValue"
      :type="typeCarousel"
      :autoplay="autoplay"
      arrow="always"
      ref="carousel"
      @change="changeItemMove()"
    >
      <el-carousel-item v-for="item in itemsCarousel" :key="item.id">
        <template v-if="!item.type_data || item.type_data === 'image'">
          <div class="carousel-container-item">
            <img
              @click="redirectUrl(item.redirect_url)"
              :src="item.source"
              :alt="item.name"
              class="imagen-carousel"
            />
          </div>
        </template>
        <template v-else>
          <div class="video-container">
            <div :id="'player-' + item.id" class="iframe-wrapper"></div>
          </div>
        </template>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loadYouTubeAPI from "@/Extend/VideoConverter";

export default {
  name: "CarouselScheme",
  mixins: [loadYouTubeAPI],
  props: {
    intervalValue: Number,
    typeCarousel: String,
    carouselDataLoaded: Boolean,
  },
  data() {
    return {
      autoplay: true,
    };
  },
  computed: {
    ...mapGetters({
      itemsCarousel: "carousel/getCarouselItems",
    }),
  },
  watch: {
    carouselDataLoaded() {
      if (this.carouselDataLoaded) {
        this.loadYouTubeAPI();
      }
    },
  },
  methods: {
    changeItemMove() {
      this.pauseAllVideos();
    },
    redirectUrl(url) {
      if (url !== null) {
        window.open(`${url}`, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  @media screen and (min-width: 1200px) and (max-width: 1800px) {
    width: 880px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

.carousel-container {
  height: 100%;
  margin: 20px auto;
  &-item {
    width: 100%;
    height: 100%;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  line-height: 200px;
  margin: 0;
}

.imagen-carousel,
.iframe-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
  @media screen and (min-width: 1200px) and (max-width: 1800px) {
    width: 880px;
    height: 200px;
  }
}

::v-deep {
  .el-carousel {
    &__item {
      iframe {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
    &__container {
      height: 230px;
    }
    &__mask {
      background-color: #043663;
      opacity: 0.7;
      border-radius: 20px;
    }
  }
}
</style>
