export default {
  name: "videoConverter",
  methods: {
    loadYouTubeAPI() {
      if (!window.YT) {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = this.setupPlayers;
      } else {
        this.setupPlayers();
      }
    },
    setupPlayers() {
      this.itemsCarousel.forEach((item) => {
        if (item.type_data === "video") {
          try {
            const player = new window.YT.Player(`player-${item.id}`, {
              videoId: this.extractVideoId(item.source),
              events: {
                onReady: (event) => {
                  item.player = event.target;
                },
                onStateChange: this.onPlayerStateChange,
              },
            });
            this.$set(item, 'player', player);
          } catch (error) {
            console.error(`Error initializing player for item ${item.id}:`, error);
          }
        }
      });
    },
    onPlayerStateChange(event) {
      if (event.data === window.YT.PlayerState.PLAYING) {
        this.autoplay = false;
      } else if (event.data === window.YT.PlayerState.PAUSED) {
        this.autoplay = true;
      } else if (event.data === window.YT.PlayerState.ENDED) {
        this.autoplay = true;
      }
    },
    extractVideoId(url) {
      const regex =
          /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = url.match(regex);
      return match && match[1] ? match[1] : null;
    },
    pauseAllVideos() {
      this.itemsCarousel.forEach((item) => {
        if (item.type_data === "video" && item.player) {
          try {
            if (typeof item.player.pauseVideo === 'function') {
              item.player.pauseVideo();
            } else {
              console.warn(`Player for item ${item.id} does not have a pauseVideo function`);
            }
          } catch (error) {
            console.error(`Error pausing video for item ${item.id}:`, error);
          }
        }
      });
    },
  },
};
